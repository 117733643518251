import React, { ReactElement } from "react";
import MainBanner from "@components/besthomewarrantyoffers/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import BestHomeWarrantyOffersWrapper from "@components/layouts/bestHomeWarrantyOffersWrapper";

const OffersList = dynamic(() => import("@components/shared/offersList"));

import Benefits from "@components/besthomewarrantyoffers/benefits";
import HowIsHomeWarranty from "@components/besthomewarrantyoffers/howIsHomeWarranty";
import WhatIsHomeWarranty from "@components/besthomewarrantyoffers/whatIsHomeWarranty";
import WhatAreBenefits from "@components/besthomewarrantyoffers/whatAreBenefits";
import LiesBeneath from "@components/besthomewarrantyoffers/liesBeneath";
import LeaveHome from "@components/besthomewarrantyoffers/leaveHome";
import NewJerseyDisclaimer from "@components/shared/newJerseyDisclaimer";
export default function BestHomeWarrantyOffersHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <BestHomeWarrantyOffersWrapper>
            <MainBanner />
            <WhatIsHomeWarranty />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <HowIsHomeWarranty />
            <WhatAreBenefits />
            <LiesBeneath />
            <Benefits />
            <LeaveHome />
            <NewJerseyDisclaimer backGround="#fffaef" />
        </BestHomeWarrantyOffersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
